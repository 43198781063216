<style>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: A4 landscape;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    #title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }
</style>

<template>
    <div>
        <p id="title">Canvas - Print Actual Picking</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>Territory</th>
                    <th>:</th>
                    <th>{{ selectedTerritory.label }}</th>
                </tr>
                <tr>
                    <th>Warehouse</th>
                    <th>:</th>
                    <th>{{ selectedWarehouse.label }}</th>
                </tr>
                <tr>
                    <th>Sales</th>
                    <th>:</th>
                    <th>{{ selectedSales.label }}</th>
                </tr>
                <tr>
                    <th>Loading Date</th>
                    <th>:</th>
                    <th>{{ formatDate(loadingDate) }}</th>
                </tr>
                <tr>
                    <th>Canvas Item Request Document</th>
                    <th>:</th>
                    <th>{{ code }}</th>
                </tr>
                <tr>
                    <th>Canvas Item Request Picking Code</th>
                    <th>:</th>
                    <th>{{ pickCode }}</th>
                </tr>
            </table>
        </div>
        <table id="table-list">
            <thead>
                <tr>
                    <th style="text-align: center" colspan="12">
                        Rincian Barang Actual Picking
                    </th>
                </tr>
                <tr></tr>
                <tr>
                    <th rowspan="2" style="vertical-align: middle; text-align:center; width: 22%">SKU Description</th>
                    <th colspan="5" style="vertical-align: middle; text-align:center;">Suggestion</th>
                    <th colspan="5" style="vertical-align: middle; text-align:center;">Actual</th>
                </tr>
                <tr>
                    <th style="vertical-align: middle; text-align:center; width: 7%">HU</th>
                    <th style="vertical-align: middle; text-align:center; width: 3%">Qty</th>
                    <th style="vertical-align: middle; text-align:center; width: 10%">Storage Area</th>
                    <th style="vertical-align: middle; text-align:center; width: 12%">Batch - Exp. Date</th>
                    <th style="vertical-align: middle; text-align:center; width: 7%;">HU</th>
                    <th style="vertical-align: middle; text-align:center; width: 3%;">Qty</th>
                    <th style="vertical-align: middle; text-align:center; width: 10%;">Storage Area</th>
                    <th style="vertical-align: middle; text-align:center; width: 12%;">Batch - Exp. Date</th>
                </tr>
            </thead>

            <tbody>
                <template v-for="(skuLine, index) in skuLines">
                    <tr style="padding-bottom: 5px" :key="index">
                        <td class="td vs-table--td align-top" style="padding: 5px"
                            :rowspan="firstRowSpan(index)">
                            {{ skuLine.sku_code }} - {{ skuLine.item_name }}
                        </td>

                        <!-- first row - suggestion -->
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            {{ skuLines[index].suggestion_lines[0].unit }}
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            {{ skuLines[index].suggestion_lines[0].quantity }}
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            {{ skuLines[index].suggestion_lines[0].storage_code }}
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            {{ skuLines[index].suggestion_lines[0].batch }}
                        </td>

                        <!-- first actual lines -->
                        <td class="td vs-table--td" style="padding: 5px">
                            {{ skuLines[index].actual_lines[0].selected_hu }}
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            {{ skuLines[index].actual_lines[0].qty }}
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            {{ skuLines[index].actual_lines[0].selected_storage_area }}
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            {{ skuLines[index].actual_lines[0].batch_number }}
                        </td>
                    </tr>

                    <!-- added lines -->
                    <template v-for="idx in skuLines[index].suggestion_lines.length > skuLines[index].actual_lines.length ? skuLines[index].suggestion_lines.length : skuLines[index].actual_lines.length">
                        <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                            <!-- ITEM SUGGESTION -->
                            <template v-if="idx < skuLines[index].suggestion_lines.length">
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[idx].unit }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[idx].quantity }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[idx].storage_code }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[idx].batch }}
                                </td>
                            </template>

                            <template
                                v-if="idx == skuLines[index].suggestion_lines.length && skuLines[index].suggestion_lines.length < skuLines[index].actual_lines.length">
                                <td class="td vs-table--td" style="padding: 5px"
                                    :rowspan="calculateRowSpan(index, idx)">
                                </td>
                                <td class="td vs-table--td" style="padding: 5px"
                                    :rowspan="calculateRowSpan(index, idx)">
                                </td>
                                <td class="td vs-table--td" style="padding: 5px"
                                    :rowspan="calculateRowSpan(index, idx)">
                                </td>
                                <td class="td vs-table--td" style="padding: 5px"
                                    :rowspan="calculateRowSpan(index, idx)">
                                </td>
                            </template>

                            <!-- actual picking -->
                            <template v-if="idx < skuLines[index].actual_lines.length">
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[idx].selected_hu }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[idx].qty }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[idx].selected_storage_area }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[idx].batch_number }}
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import _ from "lodash";import moment from "moment-timezone";

export default {
    name: "PrintPdf",
    data() {
        return {
            id: null,
            readonly: true,
            action: null,
            status: null,
            code: null,
            pickID: null,
            pickCode: null,
            selectedWarehouse: null,
            selectedTerritory: null,
            selectedSales: null,
            loadingDate: null,
            skuLines: [
                {
                    sku_code: null,
                    item_name: null,
                    option_hu: [],
                    suggestion_lines: [
                        {
                            suggest_id: 0,
                            unit: null,
                            quantity: 0,
                            storage_code: null,
                            batch: null,
                            expired_date: null,
                        },
                    ],
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            selected_storage_area: null,
                            batch_number: null,
                            expired_date: null,
                        },
                    ],
                }
            ],
            showInfo: false,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.print();
        }
    },
    methods: {
        async print() {
            await this.getData()
            await window.print()
        },
        async getData() {
            this.action = "detail";
            this.$vs.loading();
            let resp = await this.$http.get("/api/wms/v1/canvas/loading/get-picking/" + this.id + "/" + this.action);
            if (resp.code == 200) {
                this.status = resp.data.status
                this.code = resp.data.code
                this.selectedTerritory = resp.data.selected_territory
                this.selectedWarehouse = resp.data.selected_warehouse
                this.selectedSales = resp.data.selected_sales
                this.loadingDate = resp.data.loading_date
                this.pickID = resp.data.pick_id
                this.pickCode = resp.data.pick_code
                this.skuLines = resp.data.sku_lines
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
        calculateRowSpan(index, idx) {
            let suggestionRowCount = this.skuLines[index].suggestion_lines.length;
            let actualRowCount = this.skuLines[index].actual_lines.length;

            if (suggestionRowCount < actualRowCount) {
                return actualRowCount - suggestionRowCount == 0
                    ? null
                    : actualRowCount - suggestionRowCount;
            }

            if (this.skuLines[index].suggestion_lines.length == 2) {
                let lastSuggestionIndex = suggestionRowCount > 0 ? suggestionRowCount - 1 : 0;
                let startActualIndex = lastSuggestionIndex;
                let totalRows = Math.max(suggestionRowCount, actualRowCount + startActualIndex);

                return totalRows;
            } else {
                return null;
            }
        },
        firstRowSpan(index) {
            return this.skuLines[index].suggestion_lines.length >
                this.skuLines[index].actual_lines.length
                ? this.skuLines[index].suggestion_lines.length
                : this.skuLines[index].actual_lines.length;
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        formatDate_2: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>